import React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import ServicePage from "../../components/service";

export default function MappingPage() {
    const {service} = useStaticQuery(graphql`
        query {
          service: servicesJson(key: {eq: "mapping"}) {
            title
            title_long
            thumbnail {
              path {
                childImageSharp {
                  gatsbyImageData(width: 1024)
                }
              }
            }
            projects {
              title
              client
              location
            }
          }
        }
    `)
    return (
        <ServicePage service={service}>
            <p>
                REC prepares maps to spatially represent environmental features which can be used by the client to guide the location of disturbances on a landscape as well as understand the potential impacts a project may have on certain environmental sensitivities.
            </p>
        </ServicePage>
    )
}
